<template>
  <div class="upcoming-task-holder">
    <q-form class="upcoming-task-form">
      <div v-for="(upcomingTasksInMonth, index) in upcomingTasksInMonths" :key="index">
        <div class="month first"><span>{{ index }}</span></div>
        <div v-for="(upcomingTask, index2) in upcomingTasksInMonth" :key="index2">

          <q-card class="my-card info-card" v-if="upcomingTask.assinedTo" :id="upcomingTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <label>{{ upcomingTask.label }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="bg-transparent">
                      <div class="row">
                        <div class="card-avatar">
                          <q-avatar size="32px">
                            <img :src="upcomingTask.assinedTo.image">
                          </q-avatar>
                        </div>
                        <div class="card-avatar-details">
                          <div class="due-date assigned">Assigned to: <span><b>{{ upcomingTask.assinedTo.name}}</b></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="due-date">Due date: <span><b>{{ upcomingTask.dueDate._i}}</b></span></div>
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>

          <q-card class="my-card border c-purple" v-else :id="upcomingTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <q-checkbox v-model="upcomingTask.completed" :label="upcomingTask.label" @click="markCompleted(upcomingTask)" />
                <div class="due-date">Due date: <span><b>{{ upcomingTask.dueDate._i}}</b></span></div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <q-btn class="add blue custom-add" flat label="+ Assign Task" />
    </q-form>
    <div class="empty-task" style="display: none">
        <p class="text-center">Here will be your tasks</p>
        <q-btn class="add blue custom-add" flat label="+ Assign Task" />
      <div class="no-tasks-bg"></div>
    </div>
    <modal-date></modal-date>
  </div>
</template>

<script>
import ModalDate from '../Utils/ModalDate'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Upcoming',
  components: { ModalDate },
  props: {
    upcomingTasks: {
      type: Array,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      shape: 'line',
      model: null,
      avatar: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E',
      options: [
        'Google', 'Arabic', 'English', 'German', 'Spanish '
      ],
      right: false
    }
  },
  computed: {
    upcomingTasksInMonths () {
      if (this.upcomingTasks) {
        let dueDate = ''
        return this.upcomingTasks.reduce((acc, upcomingTasks) => {
          dueDate = moment(upcomingTasks.dueDate)
          if (!acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')]) acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')] = []
          acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')].push(upcomingTasks)
          return acc
        }, {})
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setTaskCompleted']),
    markCompeted (task) {
      this.setTaskCompleted(task)
    }
  }
}
</script>

<style scoped>
  .month {
    margin: 10px 0 10px 0;
  }
</style>
