import api from '../../api'

const state = {
  currentUser: {
    user_id: '',
    user_email: '',
    user_token: null,
    expiry: false,
    active: false,
    userType: null,
    userInfo: {
      generalInfo: null,
      contactDetails: null,
      bio: null
    }
  }
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  userGeneralInfo (state) {
    return state.currentUser.userInfo.generalInfo
  },
  userContactDetails (state) {
    return state.currentUser.userInfo.contactDetails
  },
  userBio (state) {
    return state.currentUser.userInfo.bio
  }
}

const actions = {
  register ({ commit }, userData) {
    return api.register(userData).then((data) => {
      return data
    })
  },
  setUser (context, data) {
    context.commit('setUser', data)
  },
  loginPassword ({ commit }, userData) {
    return api.loginPassword(userData).then((data) => {
      return data
    })
  },
  login ({ commit }, userData) {
    return api.login(userData).then((data) => {
      return data
    })
  },
  forgotPassword ({ commit }, userData) {
    return api.forgotPassword(userData).then((data) => {
      return data
    })
  },
  resetPassword ({ commit }, userData) {
    return api.resetPassword(userData).then((data) => {
      return data
    })
  },
  logOut ({ commit }) {
    commit('setUser', null)
  },
  setGeneralInfo (context, userData) {
    return api.setUserGeneralInfo({
      token: context.state.currentUser.user_token,
      avatar: userData.avatar,
      first_name: userData.name,
      last_name: userData.lastName,
      dob: userData.dob,
      gender: userData.gender,
      gender_birth: userData.genderIdentity ? 'yes' : 'no',
      first_language: userData.firstLanguage,
      other_languages: userData.languages.join(', ')
    }).then(() => {
      context.commit('setUserGeneralInfo', userData)
    })
  },
  setContactDetails (context, userData) {
    console.log(userData.address)
    return api.setUserContactDetails({
      token: context.state.currentUser.user_token,
      phone_number: userData.mainPhone,
      phone_numbers: userData.phoneNumbers.join(','),
      emails: userData.emails.join(','),
      address: userData.address,
      city: userData.city
    }).then(() => {
      context.commit('setUserContactDetails', userData)
    })
  },
  setBio (context, userData) {
    return api.setUserBio({
      token: context.state.currentUser.user_token,
      about_me: userData.aboutMe,
      allergies: userData.medical,
      achievements: JSON.stringify(userData.achievements),
      care_history: JSON.stringify({ test: 'test2' })
    }).then(() => {
      context.commit('setUserBio', userData)
    })
  },
  getUserInfo (context) {
    return api.getUserInfo({ token: context.state.currentUser.user_token }).then(userData => {
      userData = userData.data
      try {
        const generalInfo = {
          firstName: userData.first_name,
          lastName: userData.last_name,
          dob: userData.dob,
          gender: userData.gender,
          showGenderIdentity: userData.gender_birth === 'yes',
          firstLanguage: userData.firstLanguage,
          languages: userData.other_languages,
          avatar: userData.avatar
        }
        context.commit('setUserGeneralInfo', generalInfo)
      } catch (e) {
        console.log(e)
      }
      try {
        const contactDetails = {
          mainPhone: userData.phone_number,
          phoneNumbers: userData.phone_numbers,
          emails: userData.emails,
          address: userData.address,
          city: userData.city
        }
        context.commit('setUserContactDetails', contactDetails)
      } catch (e) {
        console.log(e)
      }
      try {
        const bio = {
          aboutMe: userData.about_me,
          medical: userData.allergies,
          achievements: JSON.parse(userData.achievements)
        }
        context.commit('setUserBio', bio)
      } catch (e) {
        console.log(e)
      }
    })
  }
}

const mutations = {
  setUser (state, data) {
    if (data === null) {
      state.currentUser.user_id = ''
      state.currentUser.user_token = null
      state.currentUser.expiry = false
      state.currentUser.active = false
      state.currentUser.userType = null
      state.currentUser.user_email = null
    } else {
      state.currentUser.user_id = data.data.user_id
      state.currentUser.user_token = data.data.user_token
      state.currentUser.expiry = data.data.expiry
      state.currentUser.active = true
      state.currentUser.userType = data.data.user_type
      state.currentUser.user_email = data.data.user_email
    }
  },
  setUserGeneralInfo (state, data) {
    state.currentUser.userInfo.generalInfo = data
  },
  setUserContactDetails (state, data) {
    state.currentUser.userInfo.contactDetails = data
  },
  setUserBio (state, data) {
    state.currentUser.userInfo.bio = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
