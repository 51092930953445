import axios from 'axios/index'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const getData = function (method, url, requestData = null) {
  return new Promise((resolve, reject) => {
    http.get('/wpapp/auth/v2/nonce', { 'Content-Type': 'application/json' }).then(resp => {
      requestData.nonce = resp.data.data.nonce
      if (requestData !== null) {
        http[method](url, requestData, { headers: { 'Content-Type': 'application/json' } }).then(resp => resolve(resp.data)).catch(e => reject(e))
      } else {
        http[method](url, { headers: { 'Content-Type': 'application/json' } }).then(resp => resolve(resp.data)).catch(e => reject(e))
      }
    })
  })
}

export default {
  register (data) {
    return getData('post', '/wpapp/api/v2/user/register', data)
  },
  loginPassword (data) {
    return getData('post', '/wpapp/api/v2/user/login_password?auth=email', data)
  },
  login (data) {
    return getData('post', '/wpapp/api/v2/user/login?auth=email', data)
  },
  forgotPassword (data) {
    return getData('post', '/wpapp/api/v2/user/forgot_password', data)
  },
  resetPassword (data) {
    return getData('post', '/wpapp/api/v2/user/reset_password', data)
  },
  setUserGeneralInfo (data) {
    return getData('post', '/wpapp/api/v2/user/edit_profile', data)
  },
  setUserContactDetails (data) {
    return getData('post', '/wpapp/api/v2/user/edit_contact', data)
  },
  setUserBio (data) {
    return getData('post', '/wpapp/api/v2/user/edit_bio', data)
  },
  getUserInfo (data) {
    return getData('post', '/wpapp/api/v2/user/info', data)
  }
}
