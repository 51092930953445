<template>
  <div>
    <div class="text-center q-pa-md start-page-content">
      <p class="text-icons">🤗 🙌</p>
      <p class="welcome-text">Welcome onboard {{ username }}. Let's get your profile complete!</p>
      <q-btn :to="{ name: 'Profile' }" class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white" unelevated
             rounded color="primary" label="Complete Profile"/>
      <h4>Meet your Social Worker</h4>

      <q-card class="my-card text-left">
        <q-card-section>
          <div class="row">
            <div class="col-3">
              <q-avatar class="edit-profile-avatar" size="7.88vh">
                <img :src="SocialWorker">
              </q-avatar>
            </div>
            <div class="col-9">
              <div class="card-title">Anita Gerber</div>
              <div class="card-text">Hello Ignacio! Don’t hesitate to ask me any questions. I'm looking forward to working with you to complete your Pathway Plan.
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import SocialWorker from '../assets/anita.png'
import avatar from '../assets/avatar.png'
import { mapGetters } from 'vuex'
export default {
  name: 'YPStartPage',
  data () {
    return {
      SocialWorker: SocialWorker
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo']),
    username () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.avatar : avatar
    }
  }
}
</script>

<style scoped>

</style>
