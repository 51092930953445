var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 text-center"},[_vm._v("Contact Details")]),_c('q-form',{ref:"contactDetails",staticClass:"contact-details-form"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"mainPhone"}},[_vm._v("Mobile Phone")]),_c('q-input',{attrs:{"disable":_vm.loading,"rules":[
            function (val) { return val !== null && val !== '' || 'Please type your phone number'; },
            function (val) { return val.match(/^[0-9]*$/g) !== null || 'Please type a valid phone number'; }
            ],"name":"mainPhone","outlined":"","placeholder":"Enter your phone number"},model:{value:(_vm.contactDetails.mainPhone),callback:function ($$v) {_vm.$set(_vm.contactDetails, "mainPhone", $$v)},expression:"contactDetails.mainPhone"}})],1),_c('q-btn',{staticClass:"add add-phone custom-add blue",attrs:{"disable":_vm.loading,"flat":"","label":"+ Add phone"},on:{"click":function($event){return _vm.addInfo('phoneNumbers')}}})],1),_vm._l((_vm.contactDetails.phoneNumbers),function(_,idx){return _c('div',{key:'phoneNumbers' + idx,staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('q-input',{attrs:{"disable":_vm.loading,"rules":[
            function (val) { return val !== null && val !== '' || 'Please type your phone number'; },
            function (val) { return val.match(/^[0-9]*$/g) !== null || 'Please type a valid phone number'; }
            ],"outlined":"","placeholder":"Enter your phone number"},model:{value:(_vm.contactDetails.phoneNumbers[idx]),callback:function ($$v) {_vm.$set(_vm.contactDetails.phoneNumbers, idx, $$v)},expression:"contactDetails.phoneNumbers[idx]"}})],1),_c('q-btn',{staticClass:"add add-phone custom-add blue",attrs:{"disable":_vm.loading,"flat":"","label":"- remove phone"},on:{"click":function($event){return _vm.removeInfo('phoneNumbers', idx)}}})],1)}),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('q-input',{attrs:{"value":_vm.currentUser.user_email,"type":"email","outlined":"","disable":"","placeholder":""}})],1),_c('q-btn',{staticClass:"add add-email custom-add blue",attrs:{"disable":_vm.loading,"flat":"","label":"+ Add Email"},on:{"click":function($event){return _vm.addInfo('emails')}}})],1),_vm._l((_vm.contactDetails.emails),function(_,idx){return _c('div',{key:'emails' + idx,staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('q-input',{attrs:{"disable":_vm.loading,"rules":[
            function (val) { return val !== null && val !== '' || 'Please type your email'; },
            function (val) { return _vm.validEmail(val) || 'Please type a valid email'; }
            ],"outlined":"","placeholder":"Enter your email"},model:{value:(_vm.contactDetails.emails[idx]),callback:function ($$v) {_vm.$set(_vm.contactDetails.emails, idx, $$v)},expression:"contactDetails.emails[idx]"}})],1),_c('q-btn',{staticClass:"add add-phone custom-add blue",attrs:{"disable":_vm.loading,"flat":"","label":"- Remove email"},on:{"click":function($event){return _vm.removeInfo('emails')}}})],1)}),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"address"}},[_vm._v("Address")]),_c('q-input',{attrs:{"disable":_vm.loading,"rules":[
            function (val) { return val !== null && val !== '' || 'Please type your address'; } ],"type":"address","outlined":"","placeholder":"Your current address"},model:{value:(_vm.contactDetails.address),callback:function ($$v) {_vm.$set(_vm.contactDetails, "address", $$v)},expression:"contactDetails.address"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"q-gutter-y-md column"},[_c('label',{attrs:{"for":"city"}},[_vm._v("City")]),_c('q-input',{attrs:{"disable":_vm.loading,"rules":[
            function (val) { return val !== null && val !== '' || 'Please type your city'; } ],"outlined":"","placeholder":"City"},model:{value:(_vm.contactDetails.city),callback:function ($$v) {_vm.$set(_vm.contactDetails, "city", $$v)},expression:"contactDetails.city"}})],1)]),_c('div',[_c('div',{ref:"saveBtn",staticClass:"row"},[_c('div',{staticClass:"col"},[_c('q-btn',{staticClass:"float-left q-btn--standard",attrs:{"outline":"","rounded":"","color":"primary","label":"Previous"},on:{"click":function($event){return _vm.$emit('prev')}}})],1),_c('div',{staticClass:"col"},[_c('q-btn',{staticClass:"float-right q-btn--standard",attrs:{"unelevated":"","rounded":"","color":"primary","label":"Next","loading":_vm.loading},on:{"click":function($event){return _vm.validate()}}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }