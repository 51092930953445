<template>
  <div class="home">
    Hello World from develop branch
    <hello-world></hello-world>
  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted () {
    if (!this.currentUser.active) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
