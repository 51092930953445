<template>
  <div>
    <q-input :disable="disable" outlined v-model="selectedDate" mask="date" :rules="['date', val => val !== null && val !== '' || errorMsg]" :placeholder="placeholder" @click="modalOpen = true"></q-input>
    <q-dialog v-model="modalOpen" position="bottom" class="brent-dialog">
      <q-card>
        <q-card-actions align="left">
          <q-btn flat label="Close" color="primary" v-close-popup />
        </q-card-actions>
        <q-card-section>
          <div class="text-h6 text-center">{{ placeholder}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-date :options="dateOptions" :flat="true" no-unset v-model="selectedDate" minimal/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalDate',
  props: {
    value: {
      default: ''
    },
    placeholder: {
      default: ''
    },
    maxDate: {
      default: false
    },
    errorMsg: {
      default: ''
    },
    disable: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      selectedDate: '',
      modalOpen: false
    }
  },
  mounted () {
    if (this.value.length) {
      this.selectedDate = this.value
    }
  },
  watch: {
    selectedDate (val) {
      this.$emit('input', val)
    },
    value (value) {
      this.selectedDate = value
      this.modalOpen = false
    }
  },
  methods: {
    dateOptions (date) {
      if (this.maxDate) {
        return date <= this.maxDate
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
