<template>
  <div class="completed-task-holder">
    <q-form class="general-info-form">
      <div v-for="(completedTasksInMonth, index) in completedTasksInMonths" :key="index">
        <div class="month first"><span>{{ index }}</span></div>
        <div v-for="(completedTask, index2) in completedTasksInMonth" :key="index2">

          <q-card class="my-card info-card" v-if="completedTask.assinedTo" :id="completedTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <label>{{ completedTask.label }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="bg-transparent">
                      <div class="row">
                        <div class="card-avatar">
                          <q-avatar size="32px">
                            <img :src="completedTask.assinedTo.image">
                          </q-avatar>
                        </div>
                        <div class="card-avatar-details">
                          <div class="due-date assigned">Assigned to: <span><b>{{ completedTask.assinedTo.name}}</b></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="due-date">Due date: <span><b>{{ completedTask.dueDate._i}}</b></span></div>
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>

          <q-card class="my-card border c-purple" v-else :id="completedTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <q-checkbox v-model="completedTask.completed" :label="completedTask.label" @click="markCompleted(completedTask)" />
                <div class="due-date">Due date: <span><b>{{ completedTask.dueDate._i}}</b></span></div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <q-btn class="add blue custom-add" flat label="+ Assign Task" />
    </q-form>
    <div class="empty-task">
      <div class="notification-box">
        Great Job👍!<br>
        You have completed task and achived badge!
      </div>
      <p class="text-center">No completed tasks </p>
      <div class="no-tasks-bg"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// import avatar from '../../assets/av.png'
import { mapActions } from 'vuex'

export default {
  name: 'Completed',
  props: {
    completedTasks: {
      type: Array,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      shape: 'line',
      model: null,
      avatar: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E',
      options: [
        'Google', 'Arabic', 'English', 'German', 'Spanish '
      ]
    }
  },
  computed: {
    completedTasksInMonths () {
      if (this.completedTasks) {
        let dueDate = ''
        return this.completedTasks.reduce((acc, completedTasks) => {
          dueDate = moment(completedTasks.dueDate)
          if (!acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')]) acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')] = []
          acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')].push(completedTasks)
          return acc
        }, {})
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setTaskCompleted']),
    markCompeted (task) {
      this.setTaskCompleted(task)
    }
  }
}
</script>

<style scoped>
  .month {
    margin: 10px 0 10px 0;
  }
</style>
