const state = {
  appDrawerOpen: false
}

const getters = {
  appDrawerOpen (state) {
    return state.appDrawerOpen
  }
}

const actions = {
}

const mutations = {
  toggleAppDrawer (state) {
    state.appDrawerOpen = !state.appDrawerOpen
  },
  closeAppDrawer (state) {
    state.appDrawerOpen = false
  },
  openAppDrawer (state) {
    state.appDrawerOpen = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
