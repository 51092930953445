<template>
  <div>
    <q-header elevated v-if="mode === 'default'">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="toggleAppDrawer()"
          aria-label="Menu"
          icon="fas fa-bars"
        />

        <q-toolbar-title>
          Bent App
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-header class="tasks-header" v-if="mode === 'tasks'">
      <q-toolbar>
        <div class="col-4">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
          />
        </div>
        <div class="col-4 text-center">
          <q-toolbar-title>Tasks</q-toolbar-title>
        </div>
        <div class="col-4 text-right header-right">
          <q-btn flat dense icon="fas fa-plus"/>
          <q-btn flat dense icon="fas fa-search"/>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="edit-profile-header" v-if="mode === 'edit-profile'">
      <q-toolbar>
        <div class="col-2">
          <q-btn :to="{ name: 'ViewProfile' }" flat round dense icon="fas fa-chevron-left"/>
        </div>
        <div class="col-8 text-center">
          <q-toolbar-title>Editing My Profile</q-toolbar-title>
        </div>
        <div class="col-2 text-right">
          <q-btn @click="$root.$emit('save:profile')" outline rounded label="Save"/>
        </div>
      </q-toolbar>
    </q-header>
    <q-header class="view-profile-header" v-if="mode === 'view-profile'">
      <q-toolbar class="items-start">
        <div class="col-4">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
          />
        </div>
        <div class="col-4">
          <q-avatar class="edit-profile-avatar" size="8.87vh">
            <img :src="avatar">
          </q-avatar>
        </div>
        <div class="col-4 text-right">
          <q-btn :to="{ name: 'Profile' }" class="edit-btn vertical-top" outline rounded icon="fas fa-pencil-alt" label="Edit"/>
        </div>
      </q-toolbar>
      <div class="avatar-details">
        <div class="avatar-text">{{ username }}</div>
        <p>Male <span class="text-divider"></span> 16 yo (01 September 2005) </p>
        <p>Arabic speaker <span class="text-divider"></span> Unaccompanied minor </p>
        <div class="avatar-badge"><i class="av-icon">💰</i> Super Saver</div>
      </div>
    </q-header>
    <q-header class="start-page-header" v-if="mode === 'yp-start-page'">
      <q-toolbar class="items-start">
        <div class="col-12">
          <q-btn
            flat
            dense
            round
            @click="toggleAppDrawer()"
            aria-label="Menu"
            icon="fas fa-bars"
          />
        </div>
      </q-toolbar>
      <div class="avatar-details">
        <div class="row">
          <div class="col-9">
            <h4>Hello, {{ username }}</h4>
            <p>Welcome to Pathway!</p>
          </div>
          <div class="col-3 text-right">
            <q-avatar class="edit-profile-avatar" size="8.87vh">
              <img :src="avatar">
            </q-avatar>
          </div>
        </div>
      </div>
    </q-header>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import avatar from '../assets/av.png'

export default {
  name: 'AppHeader',
  computed: {
    ...mapGetters(['appDrawerOpen', 'userGeneralInfo']),
    username () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.avatar : avatar
    },
    mode () {
      let mode
      switch (this.$route.name) {
        case 'Tasks':
          mode = 'tasks'
          break
        case 'ViewProfile':
          mode = 'view-profile'
          break
        case 'YPStartPage':
          mode = 'yp-start-page'
          break
        case 'Profile':
          mode = 'edit-profile'
          break
        case 'Login' || 'Register' || 'ForgotPassword' || 'ResetPassword':
          mode = 'none'
          break
        default:
          mode = 'default'
          break
      }
      return mode
    }
  },
  methods: {
    ...mapMutations(['toggleAppDrawer'])
  }
}
</script>

<style scoped>

</style>
