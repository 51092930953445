<template>
  <q-layout view="lHh Lpr lFf">
    <app-header></app-header>
    <main-menu v-if="currentUser.active"></main-menu>
    <q-page-container>
      <q-banner class="bg-primary text-white" v-if="updateAvailable">
        New version available!
        <template v-slot:action>
          <q-btn flat color="white" label="Dismiss" @click="dismissBanner()"/>
          <q-btn flat color="white" label="Refresh" @click="refresh()" />
        </template>
      </q-banner>
      <router-view />

      <!-- Add to home screen dialog -->
      <q-dialog v-model="addToHomeDialog" persistent>
        <q-card>
          <q-card-section class="row items-center">
            <span class="q-ml-sm">Add to Home Screen</span>
          </q-card-section>

          <q-card-section class="row items-center">
            <!-- <q-avatar icon="signal_wifi_off" color="primary" text-color="white" /> -->
            <q-img
              :src="logo"
              spinner-color="white"
              style="height: 140px; max-width: 150px"
            />
            <span class="q-ml-sm">Pathway App</span>
            <span class="q-ml-sm">Pathway.co.uk</span>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary"  @click="dismiss()" v-close-popup />
            <q-btn flat label="Add" color="primary" @click="addToHomescreen()" v-close-popup/>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </q-page-container>
  </q-layout>
</template>

<script>

import logo from './assets/logo.png'
import moment from 'moment'
import wb from './registerServiceWorker'
import MainMenu from './components/MainMenu'
import { mapGetters } from 'vuex'
import AppHeader from './components/AppHeader'
export default {
  name: 'LayoutDefault',
  components: { MainMenu, AppHeader },
  data () {
    return {
      deferredPrompt: null,
      beforeinstallprompt: false,
      addToHomeDialog: false,
      today: new Date(),
      logo: logo,
      updateAvailable: false
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    addToHomescreen () {
      this.deferredPrompt.prompt()
      this.deferredPrompt = null
    },
    async dismiss () {
      localStorage.addToHomescreen = moment()
      this.deferredPrompt = null
    },
    refresh () {
      wb.addEventListener('controlling', (event) => {
        window.location.reload()
      })
      wb.messageSW({ type: 'SKIP_WAITING' })
      this.updateAvailable = false
    },
    dismissBanner () {
      this.updateAvailable = false
    },
    openDialog () {
      if (this.deferredPrompt && (localStorage.addToHomescreen === undefined || moment() > moment(localStorage.addToHomescreen).add(14, 'days')) && !this.beforeinstallprompt && this.$q.platform.is.mobile && this.currentUser.active) {
        this.addToHomeDialog = true
        this.beforeinstallprompt = true
      }
    }
  },
  mounted () {
    this.$root.$on('addToHomeScreen', this.openDialog)
    if (wb) {
      wb.addEventListener('waiting', (event) => {
        this.updateAvailable = true
      })
    }
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.deferredPrompt = e
      if ((localStorage.addToHomescreen === undefined || moment() > moment(localStorage.addToHomescreen).add(14, 'days')) && !this.beforeinstallprompt && this.$q.platform.is.mobile && this.currentUser.active) {
        this.addToHomeDialog = true // This opens the Add to home screen dialog
        this.beforeinstallprompt = true
      }
    })
  }
}
</script>

<style>
</style>
