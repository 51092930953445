<template>
  <div class="register hidden-header">
    <q-img
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position"
    />
    <h4 class="logo-text text-center">Brent</h4>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="create password">Create Password</label>
          <q-input class="pass-field" v-model="password" :type="pwdType ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors">
            <template v-slot:append>
              <q-icon
                :class="pwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="pwdType = !pwdType"
              />
            </template>
          </q-input>
          <span class="error" v-if="passwordError">{{ passwordError }}</span>
          <label for="Confirm password">Confirm Password</label>
          <q-input class="pass-field" v-model="confirmPassword" :type="repeatPwdType ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors">
            <template v-slot:append>
              <q-icon
                :class="repeatPwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="repeatPwdType = !repeatPwdType"
              />
            </template>
          </q-input>
          <span class="error" v-if="confirmPasswordError">{{ confirmPasswordError }}</span>
          <label for="Define your own security question">Define your own security question</label>
          <q-input outlined v-model="securityQuestion" placeholder="Who was my childhood hero?" @keyup="resetErrors" />
          <label for="Answer to security question">Answer to security question</label>
          <q-input outlined v-model="answer" placeholder="Enter the answer" @keyup="resetErrors" />
          <q-btn flat @click="submitForm()" class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Confirm" />
          <p class="form-text text-center">Already have an account? <router-link class="blue form-text-link" :to="{ name: 'Login' }">Sign in</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import logo from '../assets/logo.png'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Register',
  data () {
    return {
      logo: logo,
      password: '',
      pwdType: true,
      repeatPwdType: true,
      confirmPassword: '',
      securityQuestion: '',
      answer: '',
      confirmPasswordError: '',
      passwordError: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDisabled () {
      if (this.password.length && this.confirmPassword.length && this.securityQuestion.length && this.answer.length) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['register', 'setUser']),
    checkForm () {
      const re = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/
      if (!re.test(this.password)) {
        this.passwordError = 'Password must be atleast 6 character alphanumeric'
        return false
      }
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordError = 'Confirm password must be same'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        this.register({
          hash: this.$route.params.hash ? this.$route.params.hash : null,
          password: this.password,
          security_question: this.securityQuestion,
          security_answer: this.answer
        }).then((data) => {
          this.$q.loading.hide()
          if (data.status === 200) {
            this.setUser(data)
            this.$router.push({
              name: 'YPStartPage' // this one should be changed based on user type
            })
            this.$root.$emit('addToHomeScreen')
            return this.$q.notify({
              message: data.message,
              color: 'green',
              position: 'top-right'
            })
          }
          return this.$q.notify({
            message: data.message,
            color: 'red',
            position: 'top-right'
          })
        }, err => {
          this.$q.loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    resetErrors () {
      this.confirmPasswordError = ''
      this.passwordError = ''
    }
  },
  mounted () {
    if (!this.$route.params.hash) {
      this.$router.push({
        name: 'Login'
      })
    }
    if (this.currentUser.active) {
      this.$router.push({
        name: 'YPStartPage' // this one should be changed based on user type
      })
    }
  }
}
</script>

<style scoped>
  span.error {
    color: red;
  }
</style>
