<template>
  <div>
    <div class="text-h6 text-center">Contact Details</div>
    <q-form class="contact-details-form" ref="contactDetails">
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="mainPhone">Mobile Phone</label>
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please type your phone number',
              val => val.match(/^[0-9]*$/g) !== null || 'Please type a valid phone number'
              ]"
            v-model="contactDetails.mainPhone" name="mainPhone" outlined placeholder="Enter your phone number"/>
        </div>
        <q-btn :disable="loading" @click="addInfo('phoneNumbers')" class="add add-phone custom-add blue" flat
               label="+ Add phone"/>
      </div>
      <div class="form-row" v-for="(_, idx) in contactDetails.phoneNumbers" :key="'phoneNumbers' + idx">
        <div class="q-gutter-y-md column">
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please type your phone number',
              val => val.match(/^[0-9]*$/g) !== null || 'Please type a valid phone number'
              ]"
            v-model="contactDetails.phoneNumbers[idx]" outlined placeholder="Enter your phone number"/>
        </div>
        <q-btn :disable="loading"
               @click="removeInfo('phoneNumbers', idx)" class="add add-phone custom-add blue" flat label="- remove phone"/>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="email">Email</label>
          <q-input
            :value="currentUser.user_email" type="email" outlined disable placeholder=""/>
        </div>
        <q-btn :disable="loading"
               @click="addInfo('emails')" class="add add-email custom-add blue" flat label="+ Add Email"/>
      </div>
      <div class="form-row" v-for="(_, idx) in contactDetails.emails" :key="'emails' + idx">
        <div class="q-gutter-y-md column">
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please type your email',
              val => validEmail(val) || 'Please type a valid email'
              ]"
            v-model="contactDetails.emails[idx]" outlined placeholder="Enter your email"/>
        </div>
        <q-btn
          :disable="loading"
          @click="removeInfo('emails')" class="add add-phone custom-add blue" flat label="- Remove email"/>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="address">Address</label>
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please type your address',
              ]"
            type="address"
            outlined placeholder="Your current address"
            v-model="contactDetails.address"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="city">City</label>
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please type your city',
              ]"
            outlined placeholder="City"
            v-model="contactDetails.city"
          />
        </div>
      </div>
      <div>
        <div class="row" ref="saveBtn">
          <div class="col">
            <q-btn @click="$emit('prev')" class="float-left q-btn--standard" outline rounded color="primary"
                   label="Previous"/>
          </div>
          <div class="col">
            <q-btn @click="validate()" class="float-right q-btn--standard" unelevated rounded color="primary"
                   label="Next" :loading="loading"/>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import scrollToTarget from '../../mixins/scrollToTarget'

export default {
  name: 'ContactDetails',
  mixins: [scrollToTarget],
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contactDetails: {
        mainPhone: '',
        phoneNumbers: [],
        emails: [],
        address: '',
        city: ''
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userContactDetails', 'currentUser'])
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  methods: {
    ...mapActions(['setContactDetails']),
    updateLocalData () {
      if (this.userContactDetails !== null) {
        this.contactDetails = Object.assign({}, this.userContactDetails)
      }
    },
    addInfo (type) {
      this.contactDetails[type].push('')
    },
    removeInfo (type, idx) {
      this.contactDetails[type].splice(idx, 1)
    },
    validEmail (email) {
      return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g) !== null
    },
    validate () {
      this.$refs.contactDetails.validate().then(success => {
        if (success) {
          this.loading = true
          this.scroll(this.$refs.saveBtn)
          this.setContactDetails(this.contactDetails).then(() => {
            this.loading = false
            this.updateLocalData()
            this.$emit('valid')
          })
        }
      })
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  }
}
</script>

<style scoped>

</style>
