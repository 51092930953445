<template>
  <div>
    <div class="tasks-content">
      <div class="q-gutter-y-md">
        <!-- <q-card> -->
        <q-tabs
          v-model="tab"
          dense
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
        >
          <q-tab name="upcoming" label="Upcoming"/>
          <q-tab name="overdue" label="Overdue"/>
          <q-tab name="completed" label="Completed"/>
        </q-tabs>

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="upcoming">
            <upcoming :upcoming-tasks="upcomingTasks"></upcoming>
          </q-tab-panel>

          <q-tab-panel name="overdue">
            <overdue :overdue-tasks="overdueTasks"></overdue>
          </q-tab-panel>

          <q-tab-panel name="completed">
            <completed :completed-tasks="completedTasks"></completed>
          </q-tab-panel>
        </q-tab-panels>
        <!-- </q-card> -->

      </div>
    </div>
  </div>
</template>

<script>
import Upcoming from '../components/Tasks/Upcoming'
import Completed from '../components/Tasks/Completed'
import Overdue from '../components/Tasks/Overdue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Tasks',
  components: {
    Upcoming,
    Completed,
    Overdue
  },
  data () {
    return {
      tab: 'upcoming'
    }
  },
  computed: {
    ...mapGetters(['tasks', 'currentUser']),
    upcomingTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          return !task.completed && moment() < moment(task.dueDate)
        })
      }
      return []
    },
    completedTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          return task.completed
        })
      }
      return []
    },
    overdueTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => {
          return !task.completed && moment() > moment(task.dueDate)
        })
      }
      return []
    }
  },
  mounted () {
    if (!this.currentUser.active) {
      this.$router.push({
        name: 'Login'
      })
    }
  }
}
</script>
