<template>
  <q-drawer
    v-model="drawerOpen"
    @hide="closeAppDrawer()"
    @show="openAppDrawer()"
    show-if-above
    bordered
    content-class="custom-drawer"
    behavior="mobile"
    :overlay="false"
  >
    <span class="close-drawer" @click="drawerOpen = false "><i class="fas fa-times"></i></span>
    <div class="avatar-box">
      <div class="bg-transparent">
        <div class="row">
          <div class="col-4">
            <q-avatar size="72px" class="q-mb-sm">
              <img :src="avatar">
            </q-avatar>
          </div>
          <div class="col-8">
            <div class="avatar-text">{{ username }}</div>
            <div class="avatar-badge"><i class="av-icon">💰</i> Super Saver</div>
          </div>
        </div>
      </div>
    </div>
    <q-list>
      <q-item clickable tag="a" :to="{ name: 'YPStartPage' }">
        <q-item-section avatar>
          <q-icon name="fas fa-home"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Home</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fas fa-list"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Pathway Plans</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" :to="{ name: 'ViewProfile'}">
        <q-item-section avatar>
          <q-icon name="fas fa-user-circle"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>My Profile</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fas fa-user-friends"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Contacts</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a" :to="{ name: 'Tasks'}">
        <q-item-section avatar>
          <q-icon name="far fa-check-circle"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Tasks</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="far fa-clipboard"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>My Options</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="far fa-file-alt"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>Documents</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fas fa-question-circle"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>FAQ</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable tag="a">
        <q-item-section avatar>
          <q-icon name="fas fa-cog"/>
        </q-item-section>
        <q-item-section>
          <q-item-label>App Settings</q-item-label>
        </q-item-section>
      </q-item>
      <q-item @click="logout" class="last" clickable tag="a">
        <q-item-section>
          <q-item-label>Logout</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon name="fas fa-sign-out-alt"/>
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
</template>

<script>
import avatar from '../assets/avatar.png'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MainMenu',
  computed: {
    ...mapGetters(['userGeneralInfo', 'appDrawerOpen']),
    username () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.firstName : 'User'
    },
    avatar () {
      return this.userGeneralInfo !== null ? this.userGeneralInfo.avatar : avatar
    }
  },
  data () {
    return {
      drawerOpen: false
    }
  },
  methods: {
    ...mapActions(['logOut']),
    ...mapMutations(['closeAppDrawer', 'openAppDrawer']),
    logout () {
      this.logOut()
      this.$router.push({
        name: 'Login'
      })
    }
  },
  watch: {
    appDrawerOpen (open) {
      this.drawerOpen = open
    }
  }
}
</script>
