<template>
  <div class="login hidden-header human-background">
    <q-img
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position"
    />
    <h4 class="logo-text text-center">Brent</h4>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="email">Email</label>
          <q-input v-model="email" type="email" outlined placeholder="Enter your email address" @keyup="resetErrors" />
          <span class="error" v-if="emailError">{{ emailError }}</span>
          <label for="password">Password</label>
          <q-input class="pass-field" v-model="password" :type="isPwd ? 'password' : 'text'" outlined placeholder="Enter password" @keyup="resetErrors">
            <template v-slot:append>
              <q-icon
                :class="isPwd ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div v-if="question">
            <label>{{ question }}</label>
            <q-input v-model="answer" outlined placeholder="Answer security question" @keyup="resetErrors" />
          </div>
          <q-btn @click="submitForm()" flat class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Log in" />
          <p class="forgot-text text-center"><router-link class="green form-text-link" :to="{ name: 'ForgotPassword' }">Forgot your password?</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import logo from '../assets/logo.png'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      logo: logo,
      password: '',
      isPwd: true,
      answer: '',
      email: '',
      emailError: '',
      question: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isDisabled () {
      if (this.password.length && this.email.length && ((this.question.length && this.answer.length) || (!this.question.length && !this.answer.length))) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['loginPassword', 'login', 'setUser', 'getUserInfo']),
    checkForm () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(this.email)) {
        this.emailError = 'Invalid email'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        if (this.answer) {
          this.login({
            email: this.email,
            password: this.password,
            security_answer: this.answer
          }).then((data) => {
            this.$q.loading.hide()
            if (data.status === 200) {
              data.data.user_email = this.email
              this.setUser(data)
              this.getUserInfo()
              this.$router.push({
                name: 'YPStartPage' // this one should be changed based on user type
              })
              this.$root.$emit('addToHomeScreen')
              return this.$q.notify({
                message: data.message,
                color: 'green',
                position: 'top-right'
              })
            }
            return this.$q.notify({
              message: data.message,
              color: 'red',
              position: 'top-right'
            })
          }, err => {
            this.$q.loading.hide()
            return this.$q.notify({
              message: err.message,
              color: 'red',
              position: 'top-right'
            })
          })
        } else {
          this.loginPassword({
            email: this.email,
            password: this.password
          }).then((data) => {
            this.$q.loading.hide()
            if (data.status === 200) {
              this.question = data.data.security_question
              return this.$q.notify({
                message: data.message,
                color: 'green',
                position: 'top-right'
              })
            }
            return this.$q.notify({
              message: data.message,
              color: 'red',
              position: 'top-right'
            })
          }, err => {
            this.$q.loading.hide()
            return this.$q.notify({
              message: err.message,
              color: 'red',
              position: 'top-right'
            })
          })
        }
      }
    },
    resetErrors () {
      this.emailError = ''
    }
  },
  mounted () {
    if (this.currentUser.active) {
      this.$router.push({
        name: 'YPStartPage' // this one should be changed based on user type
      })
    }
  }
}
</script>

<style scoped>
span.error {
  color: red;
}
</style>
