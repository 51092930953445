<template>
  <div>
    <div class="text-h6 text-center">General Information</div>
    <q-form class="general-info-form" ref="generalInfo">
      <div class="form-row avatar-form-row">
        <div class="q-gutter-y-md column text-center">
          <q-avatar class="profile-avatar" size="14.8vh">
            <img v-if="!uploadedAvatarImg" :src="avatar">
            <img v-else :src="uploadedAvatarImg">
          </q-avatar>
          <q-file @input="getAvatarPreview" borderless input-class="add blue custom-add text-center"
                  v-model="userInfo.avatar" label="Add photo">
            <div slot="label" class="add blue custom-add text-center">Add Photo</div>
            <div slot="file"></div>
          </q-file>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="Name">Name</label>
          <q-input
            v-model="userInfo.firstName"
            outlined
            placeholder="Enter your first name"
            :rules="[
              val => val !== null && val !== '' || 'Please type your name',
              val => val.length > 3 || 'Please type at least 3 characters'
              ]"
            :disable="loading"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="Last Name">Last Name</label>
          <q-input
            v-model="userInfo.lastName"
            outlined
            placeholder="Enter your last name"
            :rules="[
              val => val !== null && val !== '' || 'Please type your first name',
              val => val.length > 3 || 'Please type at least 3 characters'
              ]"
            :disable="loading"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="Date of Birth">Date of Birth</label>
          <modal-date :disable="loading" error-msg="Please enter your date of birth" :max-date="maxDate" v-model="userInfo.dob"
                      placeholder="Enter your date of birth"></modal-date>
        </div>
      </div>
      <div class="form-row form-row-radio">
        <div class="q-gutter-y-md column">
          <label class="title-label" for="sex">What is your sex?</label>
          <div class="row">
            <q-field
              v-model="userInfo.gender"
              :rules="[val => val.length || 'Please select your gender']"
              borderless
              square
              class="q-field-radio"
              :disable="loading"
            >
              <q-radio v-model="userInfo.gender" val="m" label="Male"/>
              <q-radio v-model="userInfo.gender" val="f" label="Female"/>
            </q-field>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label class="title-label" for="sex">Is the gender you identify with the same as your sex registered at
            birth?</label>
          <span class="details">This question is voluntary</span>
          <div class="row">
            <q-radio :disable="loading" v-model="userInfo.showGenderIdentity" :val="false" label="Yes"/>
            <q-radio :disable="loading" v-model="userInfo.showGenderIdentity" :val="true" label="No - enter gender identity"/>
            <q-input :disable="loading" v-if="userInfo.showGenderIdentity === true" v-model="userInfo.genderIdentity"></q-input>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="First language">First language</label>
          <q-select :rules="[val => val !== null && val !== '' || 'Please select your language']"
                    label="Select Language" outlined v-model="userInfo.firstLanguage" :options="languages"
                     :disable="loading"
          />
        </div>
        <q-btn :disable="loading" @click="addInfo('languages')" class="add blue custom-add" flat label="+ Add other languages"/>
        <div v-for="(language, idx) in userInfo.languages" :key="idx">
          <q-select label="Select Language" outlined v-model="userInfo.languages[idx]" :options="languages"
                    :disable="loading"
          />
          <q-btn :disable="loading" @click="removeInfo('languages', idx)" class="add add-phone custom-add blue" flat
                 label="- Remove language"/>
        </div>
      </div>
      <div ref="saveBtn">
        <q-btn @click="validate"
               class="no-outline no-shadow full-width q-btn--standard q-btn--rectangle bg-primary text-white" unelevated
               rounded color="primary" label="Next" :loading="loading"/>
      </div>
    </q-form>
  </div>
</template>
<script>
import avatar from '../../assets/av.png'
import languages from '../../assets/languages.json'
import ModalDate from '../Utils/ModalDate'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import scrollToTarget from '../../mixins/scrollToTarget'

export default {
  name: 'GeneralInfo',
  components: { ModalDate },
  mixins: [scrollToTarget],
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo']),
    languages () {
      return Object.values(languages).map(lang => lang.name)
    }
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  data () {
    return {
      loading: false,
      avatar: avatar,
      uploadedAvatarImg: false,
      maxDate: moment().format('YYYY/MM/DD'),
      canNext: true,
      userInfo: {
        firstName: '',
        lastName: '',
        dob: '',
        gender: false,
        showGenderIdentity: false,
        genderIdentity: '',
        firstLanguage: '',
        languages: [],
        avatar: []
      }
    }
  },
  methods: {
    ...mapActions(['setGeneralInfo']),
    updateLocalData () {
      if (this.userGeneralInfo !== null) {
        const userInfo = Object.assign({}, this.userGeneralInfo)
        if (userInfo.avatar.length) {
          this.uploadedAvatarImg = userInfo.avatar
        }
        delete userInfo.avatar
        userInfo.avatar = []
        this.userInfo = userInfo
      }
    },
    validate () {
      this.loading = true
      this.$refs.generalInfo.validate().then(success => {
        if (success) {
          this.scroll(this.$refs.saveBtn)
          const userInfo = Object.assign({}, this.userInfo)
          if (this.uploadedAvatarImg.length) {
            userInfo.avatar = this.uploadedAvatarImg
          } else {
            userInfo.avatar = ''
          }
          this.setGeneralInfo(userInfo).then(() => {
            this.updateLocalData()
            this.loading = false
            this.$emit('valid')
          })
        }
      })
    },
    addInfo (type) {
      this.userInfo[type].push('')
    },
    removeInfo (type, idx) {
      this.userInfo[type].splice(idx, 1)
    },
    getAvatarPreview (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = () => {
        this.uploadedAvatarImg = reader.result
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  }
}
</script>
<style>
.general-info-form .q-field-radio .q-field__control {
  padding-left: 0 !important;
  padding-right: 0;
}

.general-info-form .q-field-radio .q-radio__label {
  color: #1D1D1D;
}
</style>
