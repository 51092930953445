<template>
  <div class="forgotpass hidden-header human-background">
    <q-img
      :src="logo"
      spinner-color="white"
      style="max-width: 88px; margin: 0 auto; display: block"
      class="relative-position"
    />
    <h4 class="logo-text text-center">Brent</h4>
    <p class="subtitle text-center">Reset your password?</p>
    <q-form class="register-form">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column">
          <label for="password">Password</label>
          <q-input class="pass-field" v-model="password" :type="pwdType ? 'password' : 'text'" outlined placeholder="Enter new password" @keyup="resetErrors">
            <template v-slot:append>
              <q-icon
                :class="pwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="pwdType = !pwdType"
              />
            </template>
          </q-input>
          <span class="error" v-if="passwordError">{{ passwordError }}</span>
          <label for="Repeat password">Repeat password</label>
          <q-input class="pass-field" v-model="repeatPassword" :type="repeatPwdType ? 'password' : 'text'" outlined placeholder="Confirm password" @keyup="resetErrors">
            <template v-slot:append>
              <q-icon
                :class="repeatPwdType ? 'fas fa-eye' : 'far fa-eye-slash'"
                class="cursor-pointer"
                @click="repeatPwdType = !repeatPwdType"
              />
            </template>
          </q-input>
          <span class="error" v-if="repeatPasswordError">{{ repeatPasswordError }}</span>
          <q-btn @click="submitForm()" flat class="no-outline no-shadow q-btn--standard q-btn--rectangle bg-primary text-white" v-bind:class="{ disabled: isDisabled }" :disabled='isDisabled' rounded label="Reset password" />
          <p class="forgot-text text-center"><router-link class="green form-text-link" :to="{ name: 'Login' }">Log in</router-link></p>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import logo from '../assets/logo.png'
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  data () {
    return {
      logo: logo,
      password: '',
      pwdType: true,
      repeatPwdType: true,
      repeatPassword: '',
      passwordError: '',
      repeatPasswordError: ''
    }
  },
  computed: {
    isDisabled () {
      if (this.password.length && this.repeatPassword.length) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    checkForm () {
      const re = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/
      if (!re.test(this.password)) {
        this.passwordError = 'Password must be atleast 6 character alphanumeric'
        return false
      }
      if (this.password !== this.repeatPassword) {
        this.repeatPasswordError = 'Repeat password must be same'
        return false
      }
      return true
    },
    submitForm () {
      if (this.checkForm()) {
        this.$q.loading.show()
        this.resetPassword({
          hash: this.$route.query.hash ? this.$route.query.hash : null,
          password: this.password
        }).then((data) => {
          this.$q.loading.hide()
          if (data.status === 200) {
            this.$router.push({
              name: 'Login'
            })
            return this.$q.notify({
              message: data.message,
              color: 'green',
              position: 'top-right'
            })
          }
          return this.$q.notify({
            message: data.message,
            color: 'red',
            position: 'top-right'
          })
        }, err => {
          this.$q.loading.hide()
          return this.$q.notify({
            message: err.message,
            color: 'red',
            position: 'top-right'
          })
        })
      }
    },
    resetErrors () {
      this.passwordError = ''
      this.repeatPasswordError = ''
    }
  },
  mounted () {
    if (!this.$route.query.hash) {
      this.$router.push({
        name: 'Login'
      })
    }
  }
}
</script>

<style>
span.error {
  color: red;
}
</style>
