import Vue from 'vue'
import VueI18n from 'vue-i18n'
import api from './api'

Vue.use(VueI18n)

export const i18n = new VueI18n()

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  return lang
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return api.getTranslation({ code: lang }).then(data => {
    i18n.setLocaleMessage(lang, data)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
