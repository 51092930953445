<template>
  <div>
    <div class="profile-content" style="padding-top: 80px">
      <div>
        <q-tabs
          v-model="tab"
          narrow-indicator
          class="q-mb-lg profile-tabs pill-tabs"
        >
          <q-tab name="generalInfo" :class="{ active: !['generalInfo'].includes(tab) }" />
          <q-tab name="contactDetails" :class="{ active: !['generalInfo', 'contactDetails'].includes(tab) }" />
          <q-tab name="bio" :class="{ active: !['generalInfo', 'contactDetails', 'bio'].includes(tab) }" />
        </q-tabs>

        <div>
          <q-tab-panels
            v-model="tab"
            transition-prev="scale"
            transition-next="scale"
          >
            <q-tab-panel name="generalInfo">
              <general-info @valid="changeTab('contactDetails')"></general-info>
            </q-tab-panel>

            <q-tab-panel name="contactDetails">
              <contact-details @prev="changeTab('generalInfo')" @valid="changeTab('bio')"></contact-details>
            </q-tab-panel>

            <q-tab-panel name="bio">
              <bio @valid="goToViewProfile" @prev="changeTab('contactDetails')"></bio>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralInfo from '../components/Profile/GeneralInfo'
import ContactDetails from '../components/Profile/ContactDetails'
import Bio from '../components/Profile/Bio'

export default {
  name: 'Profile',
  components: {
    GeneralInfo,
    ContactDetails,
    Bio
  },
  data () {
    return {
      tab: 'generalInfo'
    }
  },
  methods: {
    changeTab (tab) {
      this.tab = tab
    },
    goToViewProfile () {
      this.$router.push({ name: 'ViewProfile' })
    }
  }
}
</script>

<style >
  .profile-tabs .q-focus-helper {
    display: none;
  }
</style>
