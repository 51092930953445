import { Workbox } from 'workbox-window'
let wb = false

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`)
    window.wb = wb
    wb.register()
  }
}

export default wb
