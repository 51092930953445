<template>
  <div v-if="userBio !== null && userContactDetails !== null && userGeneralInfo !== null">
    <div class="profile-details-holder">
      <q-list>
        <q-expansion-item
          group="somegroup"
          label="About Me"
          default-opened
          header-class="text-primary"
        >
          <q-card>
            <q-card-section>
              {{ userBio.aboutMe }}
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item
          group="somegroup"
          label="Contact details"
          header-class="text-primary">
          <q-card>
            <q-card-section>
              <q-markup-table>
                <tbody>
                <tr>
                  <td class="text-left">Phone</td>
                  <td class="text-right">{{ userContactDetails.mainPhone }}</td>
                </tr>
                <tr v-for="(phone, idx) in userContactDetails.phoneNumbers" :key="'phone' + idx">
                  <td class="text-left">Phone</td>
                  <td class="text-right">{{ phone }}</td>
                </tr>
                <tr v-for="(emails, idx) in userContactDetails.emails" :key="'email' + idx">
                  <td class="text-left">Email</td>
                  <td class="text-right">{{ emails }}</td>
                </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item
          group="somegroup"
          label="Allergies & medical conditions"
          header-class="text-primary">
          <q-card>
            <q-card-section>
              {{ userBio.medical }}
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator/>

        <q-expansion-item
          group="somegroup"
          label="Personal Achievements"
          header-class="text-primary"
          expand-icon-class="text-white"
          class="achievements-box"
        >
          <q-card>
            <q-card-section>
              <div v-if="userBio.achievements.length">
                <div class="row" v-for="(achievement, idx) in userBio.achievements" :key="idx">
                  <div class="col-2 vertical-middle achievements-year">
                    {{ achievement.year }}
                  </div>
                  <div class="col-10 vertical-middle achievements-details">
                    {{ achievement.description }}
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import avatar from '../assets/avatar.png'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ViewProfile',
  data () {
    return {
      avatar: avatar
    }
  },
  computed: {
    ...mapGetters(['userGeneralInfo', 'userContactDetails', 'userBio'])
  },
  methods: {
    ...mapActions(['getUserInfo'])
  },
  mounted () {
    this.getUserInfo()
  }
}
</script>

<style scoped>

</style>
