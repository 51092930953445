const state = {
  tasks: [
    {
      id: 1,
      label: 'Attend DWP appointment at local job Center, plus don\'t forget your CV',
      completed: false,
      dueDate: '06/03/21',
      assinedTo: null
    },
    {
      id: 2,
      label: 'Visit training',
      completed: false,
      dueDate: '07/10/21',
      assinedTo: null
    },
    {
      id: 3,
      label: 'Send study education grant and documents',
      completed: false,
      dueDate: '08/09/21',
      assinedTo: {
        name: 'Anita Geber',
        image: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E'
      }
    },
    {
      id: 4,
      label: 'Medical Check up',
      completed: false,
      dueDate: '06/07/21',
      assinedTo: null
    },
    {
      id: 5,
      label: 'Add document',
      completed: false,
      dueDate: '05/05/21',
      assinedTo: null
    },
    {
      id: 6,
      label: 'Send study education grant and documents',
      completed: false,
      dueDate: '05/05/21',
      assinedTo: {
        name: 'Anita Geber',
        image: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E'
      }
    },
    {
      id: 7,
      label: 'Apply for traning',
      completed: true,
      dueDate: '03/25/21',
      assinedTo: null
    },
    {
      id: 8,
      label: 'Visiting doctor',
      completed: true,
      dueDate: '03/15/21',
      assinedTo: null
    },
    {
      id: 9,
      label: 'Send study education grant and documents',
      completed: true,
      dueDate: '12/03/21',
      assinedTo: {
        name: 'Anita Geber',
        image: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E'
      }
    }
  ]
}

const getters = {
  tasks (state) {
    return state.tasks
  }
}

const actions = {
  setTaskCompleted (context, taskData) {
    alert()
    context.commit('setTaskCompleted', taskData)
  }
}

const mutations = {
  setTaskCompleted (state, data) {
    console.log('data', data)
    state.tasks = state.tasks.map(task => {
      if (task.id === data.id) {
        task.completed = !task.completed
        return task
      }
      return task
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
