<template>
  <div>
    <div class="text-h6 text-center">Your Bio</div>
    <q-form class="contact-details-form" ref="bio">
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="about">About me</label>
          <q-input
            :disable="loading"
            :rules="[
              val => val !== null && val !== '' || 'Please enter your bio',
            ]"
            v-model="bio.aboutMe"
            type="textarea"
            outlined
            placeholder="Tell us more about you including interest and hobbies"/>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="medical">Known allergies and / or medical conditions</label>
          <q-input
            :disable="loading"
            v-model="bio.medical"
            outlined placeholder="Enter medical information "/>
        </div>
      </div>
      <div class="form-row">
        <div class="q-gutter-y-md column">
          <label for="medical">My achievements</label>
          <div class="row" v-for="(achievement, idx) in bio.achievements" :key="idx" style="padding-bottom: 10px">
            <div class="col-4">
              <q-select
                :disable="loading"
                label="YYY" outlined v-model="bio.achievements[idx].year" :options="years"/>
            </div>
            <div class="col-8" style="padding-left: 1vh">
              <q-input :disable="loading" outlined placeholder="What did you achieve?" v-model="bio.achievements[idx].description"/>
            </div>
            <q-btn
              :disable="loading"
              v-if="idx !== 0" @click="removeAchievements(idx)" class="add add-phone custom-add blue" flat
              label="- Remove achievement"/>
          </div>
        </div>
        <q-btn :disable="loading"
               @click="addAchievements()" class="add add-phone custom-add blue" flat label="+ Add more"/>
      </div>
      <div class="form-row">
        <q-list class="q-list-custom">
          <q-expansion-item
            icon="fas fa-hand-holding-medical"
            label="Care History"
          >
            <q-card>
              <q-card-section>
                <div class="form-row">
                  <div class="q-gutter-y-md column">
                    <label for="medical">Q1</label>
                    <q-input :disable="loading" outlined placeholder="Text"/>
                  </div>
                </div>
                <div class="form-row">
                  <div class="q-gutter-y-md column">
                    <label for="medical">Q2</label>
                    <q-input :disable="loading" outlined placeholder="Text"/>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
      <div>
        <div class="row" ref="saveBtn">
          <div class="col">
            <q-btn @click="$emit('prev')" class="float-left q-btn--standard" outline rounded color="primary"
                   label="Previous"/>
          </div>
          <div class="col">
            <q-btn class="float-right q-btn--standard" @click="validate()" unelevated rounded color="primary"
                   label="Save" :loading="loading"/>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import scrollToTarget from '../../mixins/scrollToTarget'

export default {
  name: 'Bio',
  mixins: [scrollToTarget],
  data () {
    return {
      bio: {
        aboutMe: '',
        medical: '',
        achievements: [{
          year: moment().format('YYYY'),
          description: ''
        }]
      },
      expanded: false,
      loading: false,
      years: [...Array(Number.parseInt(moment().format('YYYY')) - 1980 + 1).keys()].map(i => i + 1980),
      year: moment().format('YYYY')
    }
  },
  mounted () {
    this.updateLocalData()
    this.$root.$on('save:profile', this.validate)
  },
  computed: {
    ...mapGetters(['userBio'])
  },
  methods: {
    ...mapActions(['setBio']),
    addAchievements () {
      this.bio.achievements.push({
        year: moment().format('YYYY'),
        description: ''
      })
    },
    removeAchievements (idx) {
      this.bio.achievements.splice(idx, 1)
    },
    updateLocalData () {
      if (this.userBio !== null) {
        this.bio = Object.assign({}, this.userBio)
      }
    },
    validate () {
      this.$refs.bio.validate().then(success => {
        if (success) {
          this.loading = true
          this.scroll(this.$refs.saveBtn)
          this.setBio(this.bio).then(() => {
            this.loading = false
            this.updateLocalData()
            this.$emit('valid')
          })
        }
      })
    }
  },
  beforeDestroy () {
    this.$root.$off('save:profile')
  }
}
</script>

<style scoped>

</style>
