import Vue from 'vue'
import Vuex from 'vuex'
import data from './modules/data'
import user from './modules/user'
import tasks from './modules/tasks'
import VuexPersist from 'vuex-persist'

const dataStore = (new VuexPersist({
  modules: ['data', 'user', 'tasks']
})).plugin

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    data,
    user,
    tasks
  },
  plugins: [
    dataStore
  ]
})
