<template>
  <div class="overdue-task-holder">
    <q-form class="general-info-form">
      <div v-for="(overdueTasksInMonth, index) in overdueTasksInMonths" :key="index">
        <div class="month first"><span>{{ index }}</span></div>
        <div v-for="(overdueTask, index2) in overdueTasksInMonth" :key="index2">

          <q-card class="my-card info-card" v-if="overdueTask.assinedTo" :id="overdueTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <label>{{ overdueTask.label }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="bg-transparent">
                      <div class="row">
                        <div class="card-avatar">
                          <q-avatar size="32px">
                            <img :src="overdueTask.assinedTo.image">
                          </q-avatar>
                        </div>
                        <div class="card-avatar-details">
                          <div class="due-date assigned">Assigned to: <span><b>{{ overdueTask.assinedTo.name}}</b></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="due-date">Due date: <span><b>{{ overdueTask.dueDate._i}}</b></span></div>
                  </div>
                </div>
              </div>
            </q-card-section>
          </q-card>

          <q-card class="my-card border c-purple" v-else :id="overdueTask.id">
            <q-card-section>
              <div class="q-gutter-sm">
                <q-checkbox v-model="overdueTask.completed" :label="overdueTask.label" @click="markCompleted(overdueTask)" />
                <div class="due-date">Due date: <span><b>{{ overdueTask.dueDate._i}}</b></span></div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <q-btn class="add blue custom-add" flat label="+ Assign Task"/>
    </q-form>
    <div class="empty-task">
      <p class="text-center">No overdue tasks</p>
      <div class="no-tasks-bg"></div>
    </div>
  </div>
</template>

<script>
// import avatar from '../../assets/av.png'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Overdue',
  props: {
    overdueTasks: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      shape: 'line',
      model: null,
      avatar: 'https://i.picsum.photos/id/911/900/500.jpg?hmac=QYnf5Xuqf6i6GAMvjOpH6hHqe9arHjP5Sq7TWjp0g0E',
      options: [
        'Google', 'Arabic', 'English', 'German', 'Spanish '
      ]
    }
  },
  computed: {
    overdueTasksInMonths () {
      if (this.overdueTasks) {
        let dueDate = ''
        return this.overdueTasks.reduce((acc, overdueTasks) => {
          dueDate = moment(overdueTasks.dueDate)
          if (!acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')]) acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')] = []
          acc[dueDate.format('MMMM') + ' ' + dueDate.format('YYYY')].push(overdueTasks)
          return acc
        }, {})
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setTaskCompleted']),
    markCompeted (task) {
      this.setTaskCompleted(task)
    }
  }
}
</script>

<style scoped>
.month {
  margin: 10px 0 10px 0;
}
</style>
